<template>
<div class="myspisok" v-if="omsh[0]!=null">
    <div class="label">Большой омшанник</div>
    <div class="term">
        {{+omsh[0].temp[1]}}°<span style="font-size:14pt;left: -14px;position: relative;">вн.</span>
        {{+omsh[0].temp[0]}}°<span style="font-size:14pt;left: -14px;position: relative;">нар.</span>
    </div>
    <div class="label" style="font-size:10pt">{{omsh[0].origin}}</div>
    <div class="label" style="font-size:14pt">{{omsh[0].time}}</div>
    <hr>
    <div class="label">Малый омшанник</div>
    <div class="term">{{omsh[1].temp}}°</div>
    <div class="label" style="font-size:14pt">{{omsh[1].time}}</div>
    <hr>
    <div class="label">Мокушичи</div>
    <div class="term">
        {{+omsh[2].temp}}°&nbsp;&nbsp;
        <span style="font-size:20pt;">{{+omsh[2].hym}}%</span>
    </div>
    <div class="label" style="font-size:14pt">{{omsh[2].time}}</div>
    <hr>
</div>
</template>

<script>
export default {
    mounted() {
        this.$store.commit('setActivePage', 1);
        if (this.$store.state.loggedIn) {
            this.$store.dispatch('omshdataget');
            if (this.$store.state.pusher) {
                this.$store.state.channel = this.$pusher.subscribe('lobastovy')
                    this.$store.state.channel.bind('omsh', (data) => {
                        this.$store.commit('omshdatasetPusher', data);
                    });
            }
        }
    },
    destroyed() {
        this.$store.commit('omshstopupdate');
        this.$pusher.unsubscribe('lobastovy');
    },
    computed: {
        omsh() {
            return this.$store.state.omshdata;
        }
    }
}
</script>
