<template>
<div>
    <div class="myspisok">
        <div v-if="koteldata.btstatus != 'ok'">
            <div class="label" style="font-size:16px">
                Нет соединения <br>с пеллетным котлом<br>
            </div>
            <hr>
        </div>
        <div v-else>
            <div class="label">Температура котла</div>
            <div class="temp">{{temp}}</div>
            <div class="label" style="font-size:14pt">{{koteldata.time}}</div>
            <hr>
            <div class="label">Термостат</div>
            <div style="height:70px;">
                <label-button name="-" @clck="term--" />
                <div class="child term">{{term}}°</div>
                <label-button name="+" @clck="term++" />
            </div>
            <hr>
            <div class="label">Топливо</div>
            <div class="term">{{koteldata.pellety}}%</div>
            <hr>
            <div class="label">Режим работы</div>
            <div style="height:45px;">
                <label-button name="Авто" :active="koteldata.kotelmode!='auto'" :width="[32,55]" @clck="setKotelMode(2)" />
                <label-button name="Ручн." :active="koteldata.kotelmode!='hand'" :width="[32,55]" @clck="setKotelMode(1)" />
                <label-button name="Стоп" :active="koteldata.kotelmode!='stop'" :width="[32,55]" @clck="setKotelMode(0)" />
            </div>
            <div v-if="koteldata.kotelmode=='hand'">
                <div class="label" style="font-size:18px;">Подача</div>
                <div style="height:70px;">
                    <label-button name="-" @clck="p--" />
                    <div class="child term">{{p}}</div>
                    <label-button name="+" @clck="p++" />
                </div>
                <div class="label" style="font-size:18px;">Ожидание</div>
                <div style="height:70px;">
                    <label-button name="-" @clck="o--" />
                    <div class="child term">{{o}}</div>
                    <label-button name="+" @clck="o++" />
                </div>
                <div class="label" style="font-size:18px;">Вентилятор</div>
                <div style="height:70px;">
                    <label-button name="-" @clck="v--" />
                    <div class="child term">{{v}}</div>
                    <label-button name="+" @clck="v++" />
                </div>
            </div>
            <div v-else-if="koteldata.kotelmode=='auto'">
                <div class="label" style="font-size:16px">{{automatState}}</div>
                <div class="label" style="font-size:16px">Режим автомата № {{koteldata.autotable}}</div>
            </div>
            <div v-else>
                <div class="label" style="font-size:16px"><br>Котёл выключен</div>
            </div>
            <hr>
            <div class="label">Розжиг</div>
            <div style="height:45px;">
                <label-button name="Выкл" :active="rozgig != 0" :width="[49,55]" @clck="rozgig--" />
                <label-button name="Вкл" :active="rozgig != 1" :width="[49,55]" @clck="rozgig++" />
            </div><br>
            <div class="label" style="font-size:16px;">Пламя: {{koteldata.fire}}</div>
            <hr>
        </div>
        <div class="label">Электрокотёл</div>
        <div style="height:45px;">
            <label-button name="Выкл" :active="ekotel.on" :width="[49,55]" @clck="sendEkotelCommand('off', 'выключение электрокотла')" />
            <label-button name="Вкл" :active="!ekotel.on" :width="[49,55]" @clck="sendEkotelCommand('on', 'включение электрокотла')" />
        </div><br>
        <hr>
        <div class="label">Термостат эл.котла</div>
        <div style="height:70px;">
            <label-button name="-" @clck="$store.dispatch('ekoteldatasend', '-')" />
            <div class="child term">{{ekotel.term}}°</div>
            <label-button name="+" @clck="$store.dispatch('ekoteldatasend', '+')" />
        </div>
    </div>
</div>
</template>

<script>
import labelButton from '../components/labelButton.vue';
export default {
    components: { labelButton },
    mounted() {
        this.$store.commit('setActivePage', 0);
        if (this.$store.state.loggedIn)
            this.$store.dispatch('koteldataget');
        if (this.$store.state.pusher) {
            this.$store.state.channel = this.$pusher.subscribe('lobastovy');
            this.$store.state.channel.bind('kotel', data => {
                if (this.$store.state.menu.bottom == false){
                    this.$store.commit('koteldataset', data);
                }
            });
        }
    },
    destroyed() {
        this.$store.commit('kotelstopupdate');
        this.$pusher.unsubscribe('lobastovy');
    },
    computed: {
        ekotel() {
            return this.$store.state.ekotel;
        },
        btstatus() {
            return this.$store.btstatus;
        },
        koteldata() {
            return this.$store.state.koteldata;
        },
        automatState() {
            return this.$store.state.automatState[this.koteldata.autostat];
        },
        temp() {
            return ( Math.floor(this.koteldata.temp/10) ) + '.' + ( this.koteldata.temp % 10 ) + '°';
        },
        term: {
            get() {
                return this.koteldata.term;
            },
            set(val) {
                this.$store.commit('koteldataedit', { type: 'term', val });
            }
        },
        p: {
            get() {
                return this.koteldata.p;
            },
            set(val) {
                this.$store.commit('koteldataedit', { type: 'p', val });
            }
        },
        o: {
            get() {
                return this.koteldata.o;
            },
            set(val) {
                this.$store.commit('koteldataedit', { type: 'o', val });
            }
        },
        v: {
            get() {
                return this.koteldata.v;
            },
            set(val) {
                this.$store.commit('koteldataedit', { type: 'v', val });
            }
        },
        rozgig: {
            get() {
                return this.koteldata.rozgig;
            },
            set(val) {
                this.$Modal.confirm({
                    title: 'Подтверждение',
                    content: 'Вы подтверждаете изменение режима работы авторозжига?',
                    width: 250,
                    cancelText: "Отмена",
                }).then(() => {
                    this.$Message.info('Данные отправлены');
                    this.$store.dispatch('koteldatasend', { vars: '7,', vals: val + ',' });
                }).catch(() => console.log('Canceled'));
            }
        }
    },
    methods: {
        setKotelMode(mode) {
            this.$Modal.confirm({
                title: 'Подтверждение',
                content: 'Вы подтверждаете изменение режима работы котла?',
                width: 250,
                cancelText: "Отмена",
            }).then(() => {
                this.$Message.info('Данные отправлены');
                this.$store.dispatch('koteldatasend', { vars: '29,', vals: mode + ',' });
            }).catch(() => console.log('Canceled'));
        },
        sendTermCommand(cmd, text) {
            this.$Modal.confirm({
                title: 'Подтверждение',
                content: 'Вы подтверждаете ' + text + '?',
                width: 250,
                cancelText: "Отмена",
            }).then(() => {
                this.$Message.info('Данные отправлены');
                this.$store.dispatch('termdatasend', cmd);
            }).catch(() => console.log('Canceled'));
        },
        sendEkotelCommand(cmd, text) {
            this.$store.state.ekotel.blocked = true;
            this.$Modal.confirm({
                title: 'Подтверждение',
                content: 'Вы подтверждаете ' + text + '?',
                width: 250,
                cancelText: "Отмена",
            }).then(() => {
                this.$Message.info('Данные отправлены');
                this.$store.dispatch('ekoteldatasend', cmd);
            }).catch(() => {
                console.log('Canceled');
                this.$store.state.ekotel.blocked = false;
            });
        }
    }
}
</script>
