import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AtComponents from 'at-ui'
import 'at-ui-style'
import VueCookies from 'vue-cookies'

Vue.use(require('vue-pusher'), {
    api_key: '9c8eb160eff9c70de6ea',
    options: {
        cluster: 'eu',
    }
});

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';

Vue.use(AtComponents)
Vue.use(VueCookies)

Vue.config.productionTip = false;
Vue.$cookies.config('10y')

Vue.component('label-button', require('./components/labelButton.vue').default);
Vue.component('term-card', require('./components/termCard.vue').default);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')