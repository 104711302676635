<template>
<div class="myspisok" v-if="termdata.term">
    <term-card v-for="(name ,id) in names" :key="id" :id="id" :name="name" />
    <div class="label">Улица</div>
    <div class="temp">{{termdata.out}}°</div>
    <div class="label" style="font-size:14pt">{{termdata.time}}</div>
</div>
</template>

<script>
export default {
    data() {
        return {
            names: ['Гостиная', 'Баня', 'Мансарда 2', 'Холл', 'Ограда', 'Мансарда 1', 'Бойлер']
        }
    },
    mounted() {
        this.$store.commit('setActivePage', 2);
        if (this.$store.state.loggedIn) {
            this.$store.dispatch('termdataget');
            if (this.$store.state.pusher) {
                this.$store.state.channel = this.$pusher.subscribe('lobastovy');
                this.$store.state.channel.bind('kotel', data => {
                    if (this.$store.state.menu.bottom == false)
                        this.$store.commit('termdataset', data.termostat);
                });
            }
        }
    },
    destroyed() {
        this.$store.commit('termstopupdate');
        this.$pusher.unsubscribe('lobastovy');
    },
    computed: {
        termdata() {
            return this.$store.state.termdata;
        }
    }
}
</script>
