<template>
<div>
    <div class="label">{{name}}</div>
    <div style="height:70px;">
        <div class="child"><a class="green contentbtn" :class="(auto)?'':'gray'" @click="auto = !auto">Авт</a></div>
        <div class="child temp">{{temp}}</div>
        <div class="child"><a class="green contentbtn" :class="(key)?'':'gray'" @click="key = !key">Отк</a></div>
    </div>
    <div style="height:70px;" v-if="auto">
        <div class="child"><a class="green contentbtn" @click="term--">-</a></div>
        <div class="child term">{{term}}°</div>
        <div class="child"><a class="green contentbtn" @click="term++">+</a></div>
    </div><br>
    <hr>
</div>
</template>

<script>
export default {
    props: [
        'name',
        'id'
    ],
    computed: {
        temp() {
            return this.$store.state.termdata.temp[this.id];
        },
        term: {
            get() {
                return this.$store.state.termdata.term[this.id];
            },
            set(val) {
                this.$store.commit('termdataedit', { type: 'term', val, id: this.id });
            }
        },
        key: {
            get() {
                return this.$store.state.termdata.key[this.id];
            },
            set(val) {
                this.$store.commit('termdataedit', { type: 'key', val, id: this.id });
            }
        },
        auto: {
            get() {
                return this.$store.state.termdata.auto[this.id];
            },
            set(val) {
                this.$store.commit('termdataedit', { type: 'auto', val, id: this.id });
            }
        }
    }
}
</script>
