<template>
<div class="child" :style="'width:'+width[0]+'%;'">
    <a v-if="active" class="green contentbtn" :style="'width:'+width[1]+'%; cursor:default'" @click="$emit('clck')">{{name}}</a>
    <span v-else class="label" style="padding: 8px 16px; height: 25px; display: inline-block;">{{name}}</span>
</div>
</template>

<script>
export default {
    props: {
        name: {
            default: ''
        },
        active: {
            default: true
        },
        width: {
            default () { return ['', ''] }
        }
    }
}
</script>
