<template>
  <div id="app" @click="if (adminmenu) adminmenu = false;">
    <table class="parent">
      <tbody>
        <tr>
          <td>
            <div class="boxShadow" align="center">
              <table class="screen">
                <tbody>
                  <tr>
                    <td class="menu">
                      <table class="menu">
                        <tbody>
                          <tr>
                            <td class="menuleft">
                              <at-button
                                type="text"
                                icon="icon-menu"
                                @click="showmenu"
                                >Меню</at-button
                              >

                              <at-menu
                                v-if="adminmenu"
                                mode="vertical"
                                width="150px"
                                style="
                                  position: absolute;
                                  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
                                "
                                @on-select="select"
                              >
                                <at-submenu>
                                  <template slot="title"
                                    >Pusher
                                    {{
                                      $store.state.pusher ? "вкл." : "выкл."
                                    }}</template
                                  >
                                  <at-menu-item name="1-1"
                                    >Включить</at-menu-item
                                  >
                                  <at-menu-item name="1-2"
                                    >Выключить</at-menu-item
                                  >
                                </at-submenu>
                                <at-submenu>
                                  <template slot="title">Режим работы</template>
                                  <at-menu-item name="2-1"
                                    >Активный</at-menu-item
                                  >
                                  <at-menu-item name="2-2"
                                    >Пассивный</at-menu-item
                                  >
                                </at-submenu>
                                <at-submenu>
                                  <template slot="title"
                                    >Ya.IOT обновление
                                    {{
                                      $store.state.ekotel.enabled
                                        ? "10 сек."
                                        : "выкл."
                                    }}</template
                                  >
                                  <at-menu-item name="2.1-1"
                                    >По таймеру 10 сек.</at-menu-item
                                  >
                                  <at-menu-item name="2.1-2"
                                    >Выключить</at-menu-item
                                  >
                                </at-submenu>
                                <at-menu-item name="3">Обновить</at-menu-item>
                                <at-submenu>
                                  <template slot="title">Перезапуск</template>
                                  <at-menu-item name="3-1"
                                    >Bluetooth</at-menu-item
                                  >
                                  <at-menu-item name="3-2"
                                    >WIFI термостата</at-menu-item
                                  >
                                  <at-menu-item name="3-3"
                                    >Выключить БП</at-menu-item
                                  >
                                  <at-menu-item name="3-4"
                                    >Включить БП</at-menu-item
                                  >
                                  <at-menu-item name="3-5"
                                    >Перезапуск APK</at-menu-item
                                  >
                                </at-submenu>
                                <at-submenu>
                                  <template slot="title"
                                    >СМС
                                    {{
                                      $store.state.sms ? "вкл." : "выкл."
                                    }}</template
                                  >
                                  <at-menu-item name="4-1">TERM?</at-menu-item>
                                  <at-menu-item name="4-2"
                                    >Включить</at-menu-item
                                  >
                                  <at-menu-item name="4-3"
                                    >Выключить</at-menu-item
                                  >
                                </at-submenu>
                                <at-submenu>
                                  <template slot="title"
                                    >Розетка термостата</template
                                  >
                                  <at-menu-item name="5-1"
                                    >Включить</at-menu-item
                                  >
                                  <at-menu-item name="5-2"
                                    >Выключить</at-menu-item
                                  >
                                </at-submenu>
                                <at-submenu>
                                  <template slot="title"
                                    >Я.Розетка
                                    {{
                                      $store.state.rozetka.on ? "вкл." : "выкл."
                                    }}</template
                                  >
                                  <at-menu-item name="6-1"
                                    >Включить</at-menu-item
                                  >
                                  <at-menu-item name="6-2"
                                    >Выключить</at-menu-item
                                  >
                                </at-submenu>
                              </at-menu>
                            </td>
                            <td class="label menucenter">{{ $route.name }}</td>
                            <td class="menuright"></td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="content">
                        <router-view />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="menu">
                      <hr />
                      <table class="menu">
                        <tbody>
                          <tr v-if="this.$store.state.menu.bottom">
                            <td class="btnblock">
                              <a
                                class="green contentbtn cancelbtn"
                                @click="cancelClick"
                                >Отменить</a
                              >
                              <a
                                class="green contentbtn submitbtn"
                                @click="submitClick"
                                >Применить</a
                              >
                            </td>
                          </tr>
                          <tr v-else>
                            <td class="btnblock">
                              <router-link
                                v-if="$store.state.activePage != 0 || true"
                                to="/"
                                class="green contentbtn"
                                style="width: 100px; margin-right: 5px"
                                >Котёл</router-link
                              >
                              <router-link
                                v-if="$store.state.activePage != 1 && false"
                                to="/omsh"
                                class="green contentbtn"
                                style="width: 115px; margin-right: 5px"
                                >Омшанники</router-link
                              >
                              <router-link
                                v-if="$store.state.activePage != 2 || true"
                                to="/term"
                                class="green contentbtn"
                                style="width: 100px"
                                >Термостат</router-link
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <at-modal
      :value="loginForm"
      :key="$store.state.loginCounter"
      title="Авторизация"
      width="350"
      ok-text="Войти"
      cancel-text="Отмена"
      :maskClosable="false"
      :showClose="false"
      @on-confirm="loggedIn"
      @on-cancel="loggedIn"
    >
      <p style="padding-bottom: 12px">
        <at-input v-model="login" placeholder="Логин"></at-input>
      </p>
      <p>
        <at-input
          v-model="password"
          type="password"
          placeholder="Пароль"
        ></at-input>
      </p>
    </at-modal>
    <at-modal
      v-model="adminmenu"
      v-if="false"
      title="Меню администратора"
      width="350"
      :show-footer="false"
    >
      <p v-if="$store.state.pusher">
        Pusher включен
        <a
          class="link"
          href="javascript://"
          @click="$store.commit('setPusher', false)"
          >Выключить</a
        >
      </p>
      <p v-else>
        Pusher выключен
        <a
          class="link"
          href="javascript://"
          @click="$store.commit('setPusher', true)"
          >Включить</a
        >
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="$store.dispatch('sendCommandToApp', { cmd: 'START', msg: 0 })"
          >START</a
        >
        Включить активный режим работы приложения
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="$store.dispatch('sendCommandToApp', { cmd: 'STOP', msg: 0 })"
          >STOP</a
        >
        Включить пассивный режим работы приложения
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="
            $store.dispatch('sendCommandToApp', { cmd: 'UPDATE', msg: 0 })
          "
          >UPDATE</a
        >
        Получить данные от приложения вне очереди (чтобы не ждать 10 сек)
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="
            $store.dispatch('sendCommandToApp', { cmd: 'RECONNECT', msg: 0 })
          "
          >RECONNECT</a
        >
        Перезагрузить Bluetooth (при обрыве связи)
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="
            $store.dispatch('sendCommandToApp', { cmd: 'REBOOT', msg: 0 })
          "
          >REBOOT:0</a
        >
        Перезагрузить модуль связи термостата
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="
            $store.dispatch('sendCommandToApp', { cmd: 'REBOOT', msg: 1 })
          "
          >REBOOT:1</a
        >
        Внешнее отключение термостата
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="
            $store.dispatch('sendCommandToApp', { cmd: 'REBOOT', msg: 2 })
          "
          >REBOOT:2</a
        >
        Внешнее включение термостата
      </p>
      <p>
        <a
          class="link"
          href="javascript://"
          @click="
            $store.dispatch('sendCommandToApp', { cmd: 'STARTAPP', msg: 0 })
          "
          >STARTAPP</a
        >
        Перезапуск приложения через Webhook
      </p>
      <p v-if="$store.state.sms">
        <a
          class="link"
          href="javascript://"
          @click="$store.dispatch('sendCommandToApp', { cmd: 'TERM?', msg: 0 })"
          >TERM?</a
        >
        Запрос данных термостата
      </p>
      <p v-if="$store.state.sms">
        Передача по СМС включена
        <a
          class="link"
          href="javascript://"
          @click="$store.commit('setSms', false)"
          >Выключить</a
        >
      </p>
      <p v-else>
        Передача по СМС выключена
        <a
          class="link"
          href="javascript://"
          @click="$store.commit('setSms', true)"
          >Включить</a
        >
      </p>
    </at-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adminmenu: false,
    };
  },
  computed: {
    loginForm: {
      get() {
        return !this.$store.state.loggedIn;
      },
      set() {},
    },
    login: {
      get() {
        return this.$cookies.get("login");
      },
      set(val) {
        this.$cookies.set("login", val);
      },
    },
    password: {
      get() {
        return this.$cookies.get("password");
      },
      set(val) {
        this.$cookies.set("password", val);
      },
    },
  },
  methods: {
    showmenu() {
      setTimeout(() => {
        this.adminmenu = true;
      }, 100);
    },
    select(name) {
      switch (name) {
        case "1-1":
          this.$store.commit("setPusher", true);
          break;
        case "1-2":
          this.$store.commit("setPusher", false);
          break;
        case "2-1":
          this.$store.dispatch("sendCommandToApp", { cmd: "START", msg: 0 });
          break;
        case "2-2":
          this.$store.dispatch("sendCommandToApp", { cmd: "STOP", msg: 0 });
          break;
        case "2.1-1":
          this.$store.state.ekotel.enabled = true;
          break;
        case "2.1-2":
          this.$store.state.ekotel.enabled = false;
          break;
        case "3":
          this.$store.dispatch("sendCommandToApp", { cmd: "UPDATE", msg: 0 });
          break;
        case "3-1":
          this.$store.dispatch("sendCommandToApp", {
            cmd: "RECONNECT",
            msg: 0,
          });
          break;
        case "3-2":
          this.$store.dispatch("sendCommandToApp", { cmd: "REBOOT", msg: 0 });
          break;
        case "3-3":
          this.$store.dispatch("sendCommandToApp", { cmd: "REBOOT", msg: 1 });
          break;
        case "3-4":
          this.$store.dispatch("sendCommandToApp", { cmd: "REBOOT", msg: 2 });
          break;
        case "3-5":
          this.$store.dispatch("sendCommandToApp", { cmd: "STARTAPP", msg: 0 });
          break;
        case "4-1":
          this.$store.dispatch("sendCommandToApp", { cmd: "TERM?", msg: 0 });
          break;
        case "4-2":
          this.$store.commit("setSms", true);
          break;
        case "4-3":
          this.$store.commit("setSms", false);
          break;
        case "5-1":
          this.sendTermCommand("KOTELON", "включение розетки термостата");
          break;
        case "5-2":
          this.sendTermCommand("KOTELOF", "выключение розетки термостата");
          break;
        case "6-1":
          this.sendYaRozetkaCommand("key_on", "включение Яндекс.розетки");
          break;
        case "6-2":
          this.sendYaRozetkaCommand("key_off", "выключение Яндекс.розетки");
          break;
        default:
          alert("Нет таких значений");
      }
      this.adminmenu = false;
    },
    loggedIn() {
      this.$store.dispatch("loggedIn");
      this.$store.dispatch("ekoteldataget");
      setInterval(() => {
        this.$store.dispatch("ekoteldataget");
      }, 10000);
      setTimeout(() => {
        this.$store.dispatch("rozetkadataget");
        setInterval(() => {
          this.$store.dispatch("rozetkadataget");
        }, 10000);
      }, 5000);
    },
    cancelClick() {
      this.$store.dispatch("commonSets");
    },
    submitClick() {
      if (this.$store.state.activePage == 0)
        this.$store.dispatch("koteldatasend");
      else if (this.$store.state.activePage == 2)
        this.$store.dispatch("termdatasend");
      this.$store.dispatch("commonSets");
      this.$Message.info("Данные отправлены");
    },
    sendTermCommand(cmd, text) {
      this.$Modal
        .confirm({
          title: "Подтверждение",
          content: "Вы подтверждаете " + text + "?",
          width: 250,
          cancelText: "Отмена",
        })
        .then(() => {
          this.$Message.info("Данные отправлены");
          this.$store.dispatch("termdatasend", cmd);
        })
        .catch(() => console.log("Canceled"));
    },
    sendYaRozetkaCommand(cmd, text) {
      this.$store.state.rozetka.blocked = true;
      this.$Modal
        .confirm({
          title: "Подтверждение",
          content: "Вы подтверждаете " + text + "?",
          width: 250,
          cancelText: "Отмена",
        })
        .then(() => {
          this.$Message.info("Данные отправлены");
          this.$store.dispatch("ekoteldatasend", cmd);
        })
        .catch(() => {
          this.$store.state.rozetka.blocked = false;
        });
    },
  },
  mounted() {
    this.loggedIn();
  },
};
</script>

<style scoped>
.btnblock {
  width: 100%;
  min-width: 200px;
  text-align: center;
}

.submitbtn {
  width: 115px;
  cursor: default;
  background-color: #6190e8;
  border: 1px solid #6190e8;
}

.cancelbtn {
  width: 100px;
  cursor: default;
  margin-right: 5px;
  background-color: #e86161;
  border: 1px solid #e86161;
}

.link:hover {
  color: rgb(0, 0, 0) !important;
}
</style>
